<template>
	<div style="width: 100%">
		<el-form :rules="rules" ref="ruleForm" :model="ruleForm" class="leave_ruleForm">
		<div :class="['f-box', disabled?'form-readonly':'']">
			<div class="leave-item" v-for="(leaveItem, leaveItemIndex) in ruleForm.leaveArray" :key="leaveItemIndex">
					<div class="leave-item-head">
						<div class="leave-item-head-left">请假{{ leaveItemIndex + 1 }}</div>
						<div class="leave-item-head-right">
							<el-button type="primary" @click="handleAddLeave" v-if="!disabled  && leaveItemIndex != 4 && leaveArray.length < 5">添加</el-button>
							<el-button type="danger" v-if="!disabled  && leaveItemIndex !== 0" class="dangerBtn"
								@click="handleDeleteLeave(leaveItemIndex)">删除</el-button>
						</div>
					</div>
					<div class="leave-item-body">
						<el-form-item required label="请假日期" :prop="'leaveDate' + leaveItemIndex" :label-width="labelWidth"
							class="leave_data_item">
							<el-date-picker
								v-if="!disabled"
								:disabled="disabled"
								type="dates" 
								:readonly="disabled"
								:default-value="defaultDate"
								value-format="yyyy-MM-dd"
								v-model="leaveItem.dates.value"
								placeholder="请选择日期"
								@focus="handleFocus(leaveItemIndex)"
								@change="handleDatesChange($event,leaveItemIndex)"
							>
							</el-date-picker>
							<!-- 禁用状态下用多行显示 -->
							<el-input
								v-else
								:disabled="disabled"
								:readonly="disabled"
								v-model="leaveItem.dates.value"
								type="textarea"
								:rows="5"
								placeholder="请选择日期"
							>
							</el-input>
						</el-form-item>
						<div :class="['flex-box',{ active: leaveItem.times.length > 1 && timeItemIndex !== leaveItem.times.length - 1 }]" v-for="(timeItem, timeItemIndex) in leaveItem.times" :key="timeItem.id">
							<div class="first_div">
								<label>
									<span class="star">*&nbsp;</span>
									<span class="text">时间段{{ timeItemIndex + 1 }}</span>
								</label>
								<span v-if="!disabled  && timeItemIndex == 0" class="index_first">(最多5个)</span>
							</div>
							<div class="form-item-box">
								<el-form-item
									:prop="'leaveArray.'+leaveItemIndex+'.times'+'.'+timeItemIndex+'.timeRange'"
									:rules="timeRangeRule"
									:label-width="labelWidth"
									class="">
									<el-time-picker v-model="timeItem.timeRange" :format="format" :value-format="valueFormat" @change="handleTimeChange(leaveItemIndex)"
										is-range range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :readonly="disabled" @focus="handleTimeFocus($event)"
										placeholder="选择时间范围" @blur="handleTimerBlur">
									</el-time-picker>
								</el-form-item>
								<span class="tip_class" v-if="timeItem.tip && timeItem.tip.isShowTip && !selfTip">{{ timeItem.tip.tipContext}}</span>
								<span class="tip_class" v-else-if="timeItem.selfTip && timeItem.selfTip.isShowTip && selfTip">{{ timeItem.selfTip.tipContext}}</span>
							</div>
							<i class="el-icon-remove" v-if="!disabled  &&
								leaveItem.times.length > 1 
							" @click.stop="handleDeleteTimeItem(leaveItemIndex, timeItem.id)"></i>
							<i class="el-icon-circle-plus" v-if="!disabled  &&
								timeItemIndex == leaveItem.times.length - 1 &&
								leaveItem.times.length < 5
							" @click.stop="handleAddTimeItem(leaveItemIndex)"></i>
						</div>
						<el-form-item :label-width="labelWidth" label="请假时长" :prop="'leaveArray.'+leaveItemIndex+'.duration.value'" :rules="durationRule">
							<el-input placeholder="" readonly disabled style="width: 100%"
								v-model="leaveItem.duration.value">
								<template v-if="leaveItem.duration.unit" slot="append">{{ leaveItem.duration.unit.leaveUnit == "1" ? "天": "小时" }}</template>
							</el-input>
						</el-form-item>
					</div>
				</div>
			</div>
			<div class="dash-line"></div>
		</el-form>
	</div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { mapState } from 'vuex';
import {CampusOAModel} from "@/models/CampusOA";
export default {
	name: "Leave",
	components: {
	},
	data() {
		return {
			timeRangeRule: {
                required: true,
                validator: (rule,value,callback) => {
                    if (!value) {
						this.isShowChoseTip = true
                        callback("请选择时间段");
                    } else {
                        callback();
					}
                },
                trigger: ["change",'blur'],
            },
			durationRule: {
                required: true,
				validator: (rule,value,callback) => {
					// 使用正则表达式匹配中间的索引值
					const match = rule.field.match(/leaveArray\.(\d+)\.duration\.value/)
					let index = null
					if (match) {
						index = match[1];
					}
					if (!value || value == 0) {
						callback("时长不能为0");
					} else if (
						this.showLimit === 1 &&
						this.applicant &&
						this.applicant.findIndex(i => i.key === 'teacher') === -1 &&
						index !== null &&
						this.leaveArray[index].duration.unit === 2 &&
						this.leaveArray[index].duration.value < this.limitDuration
					) {
						// 开启请假时长限制后，针对学生请假单，用户提交请假单为小时，小于请假时长限制
						callback(`请假时长不能低于${this.limitDuration}个小时`);
					} else {
						callback();
					}
				},
                trigger: ["change",'blur'],
            },
			isShowChoseTip: false,
			selfTip: false,
			// 日历出入时间
			monthDateList: {},
			isShowCalendar: true,
			labelWidth: "90px",
			rules: {
				leaveDate: [
					{
						required: true,
						message: "请选择请假日期",
						trigger: ["blur", "change"],
					},
				],
			},
			leaveArray: [],
			format: "HH:mm",
			valueFormat: "HH:mm:ss",
			calendarIndex: -1,
			durs: {
				duration: '',
				unit: 2
			},
			durationArr: [],
			allDurObj: {
				duration: 0,
				unit: 2
			},
			maxDateLength: 31,
			defaultDate:'',
			timePickerFocus: false,
			isShowOwnTip: true,
		};
	},
	props:{
		leaveType:{
			type:String,
			default:''
		},
		speciLeaveType: {
            type: String,
        },
		tipContent: {
            type: Object
        },
		infoData: {
            type: Array
        },
		disabled: {
			type: Boolean,
			default: false
		},
		limitDuration: {
			type: [String, Number],
			default: false
		},
		showLimit: {
			type: [String, Number],
			default: false
		},
		applicant: {
			type: Array,
			default: false
		},
	},
	computed: {
		ruleForm() {
			let o = {}
			this.leaveArray.forEach((item, index) => {
				o[`leaveDate${index}`] = item.dates.value ? item.dates.value.join(',') : '';
				this.rules[`leaveDate${index}`] = [{ required: true, message: '请选择请假日期', trigger: ['blur', 'change'] }]
			})
			return o
		},
		...mapState({
			schoolId: state => state.schoolId,
        }),
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods: {	
		init() {
			this.initPageData();
		},
		initPageData() {
			if (this.infoData && this.infoData.length > 0) {
				this.leaveArray = this.infoData
				this.leaveArray.forEach(item => {
					item.times.forEach(timeItem => {
						timeItem.timeRange = [timeItem.startTime?.value,timeItem.endTime?.value]
					})
				})
			}else{
				this.leaveArray = [this.createLeaveTemplate()]
			}
		},
		/**
		 * @description: 生成子假条模板
		 * @return {*}
		 * @author: 邵澳
		 * @Date: 2023-11-06 14:28:59
		 */
		createLeaveTemplate() {
			let obj = {
				dates: {
					label: "请假日期",
					type: "specialLeaveDates",
					id: `specialLeave_${uuidv4().replaceAll("-", "")}`,
					value: [],
				},
				times: [
					{
						startTime: {
							label: "开始时间",
							type: "specialLeaveStartTime",
							id: `specialLeave_${uuidv4().replaceAll("-", "")}`,
							value: "",
						},
						endTime: {
							label: "结束时间",
							type: "specialLeaveEndTime",
							id: `specialLeave_${uuidv4().replaceAll("-", "")}`,
							value: "",
						},
						timeRange: '',
						id: `specialLeave_${uuidv4().replaceAll("-", "")}`,
						tip: {
							isShowTip: false,
							tipContext: ''
						},
						selfTip: {
							isShowTip: false,
							tipContext: ''
						},
					},
				],
				duration: {
					label: "时长",
					type: "specialLeaveDuration",
					id: `specialLeave_${uuidv4().replaceAll("-", "")}`,
					value: '',
					unit: 2,
				},
			}
			return obj;
		},
		// 选择日期修改
		async chageDateSelect(findex) {
			let checkRuleArr = this._.cloneDeep(this.leaveArray)
			checkRuleArr.forEach(item => {
				if (item.dates.value && item.dates.value.length > 0) {
					item.times.forEach(time => {
						if(time.timeRange && time.timeRange.length > 0) {
							time.startTime.value = time.timeRange[0];
							time.endTime.value = time.timeRange[1];
						}
					})
				}else {
					this.leaveArray[findex].times.forEach(timeItem => {
							timeItem.startTime.value = '';
							timeItem.endTime.value = '';
							timeItem.timeRange = '';
							timeItem.tip.isShowTip = false
							timeItem.tip.tipContext = ''
							timeItem.selfTip.isShowTip = false
							timeItem.selfTip.tipContext = ''
						})
						this.leaveArray[findex].duration.value = '';
					this.getAllDurTime();
					this.checkLeaveForm();
				}
			})
			// 处理时间段是否选择重叠
			this.handleProcessTimeOverlap(checkRuleArr);

			checkRuleArr[findex].times = checkRuleArr[findex].times.filter(ivv => ivv.startTime.value && ivv.endTime.value);
			if(checkRuleArr[findex].times.length == 0) {
				this.$emit('isProcessDisabled')
				return
			}
			await this.getDurationEach(checkRuleArr,findex)
			await this.getAllDurTime();
			// 判断时间段重复的方法
			if(checkRuleArr[findex].dates.value.length == 0 || !this.speciLeaveType) {
				return
			}
			if(checkRuleArr[findex].times.length == 0) {
				return
			}
			this.$emit('getIsRepeatCheckDate',checkRuleArr);
			this.$forceUpdate();
		},
		//添加请假
		handleAddLeave() {
			this.leaveArray.push(this.createLeaveTemplate());
		},
		// 删除请假
		async handleDeleteLeave(index) {
			this.leaveArray.splice(index, 1);
			let obj2 = this._.cloneDeep(this.leaveArray);
			obj2.forEach(item => {
				item.times.forEach(time => {
					if(time.timeRange && time.timeRange.length > 0) {
						time.startTime.value = time.timeRange[0];
						time.endTime.value = time.timeRange[1];
					}
				})
			})
			// 处理时间段是否选择重叠
			this.handleProcessTimeOverlap(obj2);
			await this.getDurationEach(obj2,index);
			this.getAllDurTime();
			this.$forceUpdate();
		},
		/**
		 * @description: 添加时间段
		 * @param {*} findex
		 * @return {*}
		 * @author: 邵澳
		 * @Date: 2023-11-01 14:09:07
		 */
		handleAddTimeItem(findex) {
			this.leaveArray[findex].times.push(this.createTimeTemplate());
			this.checkLeaveForm();
		},
		/**
		 * @description: 生成时间段模板
		 * @return {*}
		 * @author: 邵澳
		 * @Date: 2023-11-06 14:28:59
		 */
		createTimeTemplate() {
			let obj = {
				startTime: {
					label: "开始时间",
					type: "specialLeaveStartTime",
					id: `specialLeave_${uuidv4().replaceAll("-", "")}`,
					value: "",
				},
				endTime: {
					label: "结束时间",
					type: "specialLeaveEndTime",
					id: `specialLeave_${uuidv4().replaceAll("-", "")}`,
					value: "",
				},
				timeRange: '',
				id: `specialLeave_${uuidv4().replaceAll("-", "")}`,
				tip: {
					isShowTip: false,
					tipContext: ''
				},
				selfTip: {
					isShowTip: false,
					tipContext: ''
				},
			}
			return obj;
		},
		/**
		 * @description: 删除时间段
		 * @return {*}
		 * @author: 闫乔
		 * @Date: 2023-11-01 14:11:39
		 */
		async handleDeleteTimeItem(findex, sid) {
			this.leaveArray[findex].times = this.leaveArray[findex].times.filter(
				(item) => item.id !== sid,
			);
			let obj1 = this._.cloneDeep(this.leaveArray);
			obj1.forEach(item => {
				item.times.forEach(time => {
					if(time.timeRange && time.timeRange.length > 0) {
						time.startTime.value = time.timeRange[0];
						time.endTime.value = time.timeRange[1];
					}
				})
			})
			// 选择时间段，与已选择的时间段不能重复
			this.handleProcessTimeOverlap(obj1);
			await this.getDurationEach(obj1,findex);
			this.getAllDurTime();
			this.checkLeaveForm();
		},
		handleDateFormat(arr) {
			return arr.join(",");
		},
		handleMonthsList(arr) {
			let obj = {};
			arr.forEach((item) => {
				obj[item] = "";
			});
			return obj;
		},
		// 处理选择时间段重复交叉的方法
		handleProcessTimeOverlap(obj) {
			let dayAndTimeArr =[]
			obj.forEach((item)=>{
				item.timesRangeArr = item.times.map((time)=>{
					if (time.timeRange && time.timeRange.length > 0) {
						return time.timeRange
					}
				})
				if (item.timesRangeArr && item.timesRangeArr.length > 0) {
					dayAndTimeArr.push(...this.handelDataFn(item.dates.value,item.timesRangeArr))
					let isTimeRange = this.handleSome(dayAndTimeArr)
					if (isTimeRange) {
						this.selfTip = true
						this.$emit('isProcessDisabled')
					} else {
						this.selfTip = false
					}
				}
			})
        },
		handelDataFn(newDayArr,arrTime) {
			let arr = []
			if (newDayArr && newDayArr.length > 0) {
				newDayArr.forEach((day)=>{
					arrTime.forEach((time)=>{
						let query={
							startTime :'',
							endTime:''
						}
						if (time && time.length > 0) {
							query.startTime = day +' ' + time[0].substr(0,5)
							query.endTime = day + ' ' + time[1].substr(0,5)
							arr.push(query)
						}
					})
				})
			}
			return arr
		},
        handleSome(tableData) {
            let startTimeArr = []
            let endTimeArr = []
            tableData.map(function(item) {
            	startTimeArr.push(item.startTime ? new Date(item.startTime).getTime() : '')
            	endTimeArr.push(item.endTime ? new Date(item.endTime).getTime() : '')
            })
            let allStartTime = startTimeArr.sort() // 排序
            let allEndTime = endTimeArr.sort()
            let result = 0 // 判断时间是否有重复区间
			// 重置所有提示语
			this.leaveArray.forEach(fitem => {
				if (fitem.dates && fitem.dates.value.length > 0) {
					fitem.times.forEach(iv => {
						if (iv.timeRange && iv.timeRange.length > 0) {
							iv.selfTip.isShowTip = false
							iv.selfTip.tipContext = ''
						}
					})
						
				}
			})
            for (let k = 0; k < allStartTime.length; k++) {
            	if (k > 0) {
                	if (allStartTime[k] <= allEndTime[k - 1]) {
						// 冲突时间段的日期时间1
						let startOverlap = this.timestampToTime(allStartTime[k])
						// 冲突时间段的日期时间2
						let endOverlap = this.timestampToTime(allEndTime[k - 1])
						// 冲突时间1的日期
						let dayStartOverlap = startOverlap?.substring(0,10)
						// 冲突时间1的时间
						let timeStartOverlap = startOverlap?.substring(11,16)
						// 冲突时间2的日期
						let dayEndOverlap = endOverlap?.substring(0,10)
						// 冲突时间2的时间
						let timeEndOverlap = endOverlap?.substring(11,16)
						console.log('冲突的是',startOverlap,endOverlap,timeStartOverlap);
						this.leaveArray.forEach(fitem => {
							if (fitem.dates && fitem.dates.value.length > 0) {
								// 判断某个日期是否是冲突日期
								fitem.dates.value.forEach((dayItem) =>{
									// 如果是冲突日期1
									if (dayItem == dayStartOverlap) {
										// 遍历所有时间段
										fitem.times.forEach(iv => {
											if (iv.timeRange && iv.timeRange.length > 0) {
												// 处理时间段为 时:分
												let ivStartTime = iv.timeRange[0]?.substr(0,5)
												let ivEndTime = iv.timeRange[1]?.substr(0,5)
												console.log('时间段的开始时间',ivStartTime,'时间段的结束时间',timeEndOverlap);
												// 如果时间段的开始时间等于冲突时间1的开始时间
												// 或者时间段的结束时间等于冲突时间1的结束时间
												if(ivStartTime == timeStartOverlap || ivEndTime == timeEndOverlap) {
													iv.selfTip.isShowTip = true
													iv.selfTip.tipContext = '请假时间冲突！'
													this.$emit('isProcessDisabled')
												}
											}
										})
									}else if (dayItem == dayEndOverlap) { // 如果是冲突日期2
										fitem.times.forEach(iv => {
											if (iv.timeRange && iv.timeRange.length > 0) {
												let ivStartTime = iv.timeRange[0]?.substr(0,5)
												let ivEndTime = iv.timeRange[1]?.substr(0,5)
												console.log('ivStartTime',ivStartTime,'timeEndOverlap',timeEndOverlap);
												if(ivStartTime == timeStartOverlap || ivEndTime == timeEndOverlap) {
													iv.selfTip.isShowTip = true
													iv.selfTip.tipContext = '请假时间冲突！'
													this.$emit('isProcessDisabled')
												}
											}
										})
									}
									
								})
							}
						})
                		result += 1
                	}
            	}
            }
            return result > 0
		},
		timestampToTime(timestamp) {
			const date = new Date(timestamp); // 根据时间戳创建Date对象
			const year = date.getFullYear(); // 获取年份
			const month = date.getMonth() + 1; // 获取月份，需要加1
			const day = date.getDate(); // 获取日期
			const hour = date.getHours(); // 获取小时
			const minute = date.getMinutes(); // 获取分钟

			const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
			return formattedDate;
		},
		// 选择时间段
		async handleTimeChange(findex) {
			let obj = this._.cloneDeep(this.leaveArray);
			obj.forEach(item => {
				item.times.forEach(time => {
					if(time.timeRange && time.timeRange.length > 0) {
						time.startTime.value = time.timeRange[0];
						time.endTime.value = time.timeRange[1];
					}else {
						this.leaveArray[findex].times.forEach(timeItem => {
								if(!timeItem.timeRange) {
									time.startTime.value = '';
									time.endTime.value = '';
									timeItem.tip.isShowTip = false
									timeItem.tip.tipContext = ''
									timeItem.selfTip.isShowTip = false
									timeItem.selfTip.tipContext = ''
								}
							})
						this.leaveArray[findex].duration.value = '';
						this.getAllDurTime();
						
						this.checkLeaveForm();
					}
				})
			})
			// 选择时间段，与已选择的时间段不能重复
			this.handleProcessTimeOverlap(obj);

			obj[findex].times = obj[findex].times.filter(ivv => ivv.startTime.value && ivv.endTime.value);
			if(obj[findex].times && obj[findex].times.length == 0) {
				this.leaveArray[findex].duration.value = ''
				this.getAllDurTime();
				this.$emit('isProcessDisabled')
				return
			} else {
				await this.getDurationEach(obj,findex);
				this.leaveArray[findex].duration.value = this.durs.duration
				this.leaveArray[findex].duration.unit = this.durs.leaveUnit
				this.getAllDurTime();
				// 判断时间段重复的方法
				if(obj[findex].dates.value.length == 0 || !this.speciLeaveType) {
					return
				}
				this.$emit('getIsRepeatCheckDate',obj);
				this.checkLeaveForm();
				
			}
			this.$forceUpdate();
		},
		// 处理请假总时长的计算方法
		async getAllDurTime(){
			if (this.leaveArray.length > 0) {
				this.allDurObj.duration = 0;
				this.leaveArray.forEach(iv => {
					this.allDurObj.duration += iv.duration.value;
				})
				this.allDurObj.unit = this.leaveArray[0].duration.unit;
				if(String(this.allDurObj.duration).split('.')[1] && String(this.allDurObj.duration).split('.')[1].length > 2){
					this.allDurObj.duration = this.allDurObj.duration.toFixed(2)
				}
			}
			this.$emit('handleChangeDuration', this.allDurObj);
		},
 		/**
 		 * @Description: 获取单个请假组的时长
 		 * @DoWhat: 调取获取时长的接口
 		 * @UseScenarios: 
 		 * @Attention: 
 		 * @Author: 闫乔
 		 * @Date: 2023-11-07 17:26:17
 		 */		
		async getDurationEach(obj,findex) {
			let arr = this._.cloneDeep(obj);
			if (arr[findex]) {
				if(arr[findex].dates && arr[findex].dates.value.length == 0 || !this.speciLeaveType){//没选日期的话 不请求时长
					this.$refs.ruleForm.validateField(`leaveDate${findex}`);
					this.$emit('handleCheckRule', ['leaveType'])
					return
				}
				arr[findex].times = arr[findex].times.filter(sitem => sitem.startTime.value && sitem.endTime.value);
				if(arr[findex].times.length == 0) {
					return
				}
				let objForm = {
					schoolId: this.schoolId,
					vacationId: this.speciLeaveType,
					specialLeave: arr[findex]
				}
				const campusOA = new CampusOAModel();
				await campusOA
					.getProcessApplySpecialVacationDuration(objForm)
					.then((res) => {
						if (res.data.code === '200') {
							this.durs = res.data.data
							this.leaveArray[findex].duration.value = this.durs.duration;
							this.leaveArray[findex].duration.unit = this.durs.leaveUnit;
						}
					});
				
			}
		},
		// 清空请假组的内容
		clearLeaveForm() {
			for (let iitem of this.leaveArray) {
				iitem.duration.value = '';
				iitem.dates.value = [];
				iitem.times.forEach((item) =>{
					item.startTime.value = '';
					item.endTime.value = '';
					item.timeRange = ''
				})
            }
		},
		// 校验请假组的表单
		async checkLeaveForm() {
			let valid = await this.$refs.ruleForm.validate().catch(error => {
				return false;
			})
			if (this.selfTip) {
				valid = false
			}
			if (!valid) {
				this.$emit('isProcessDisabled')
			}
			return valid
		},
		//选择日期事件
		handleDatesChange(e,findex) {
			if(e == null){
				this.leaveArray[findex].dates.value = [];
			}
			if(this.leaveArray[findex].dates.value.length > this.maxDateLength){
				this.leaveArray[findex].dates.value = this.leaveArray[findex].dates.value.splice(0, this.maxDateLength);
				this.$message.warning(`最多选择${this.maxDateLength}天`);
			}
			this.chageDateSelect(findex)
		},
		//选择日期组件获取焦点事件 没选中任何日期时默认选中当天
		handleFocus(findex) {
			this.defaultDate = this.$moment().format('YYYY-MM-DD')
			if(this.leaveArray[findex].dates.value.length == 0) {
				this.leaveArray[findex].dates.value.push(this.defaultDate);
				this.chageDateSelect(findex);
			}
		},
		handleTimeFocus(e) {
			this.timePickerFocus = true;
			setTimeout(() => {
				this.timePickerFocus = false;
			},200)
			let offsetTop = parseInt(e.$el.getBoundingClientRect().bottom);//元素顶部距离文档顶部的距离
            let clientHeight = document.body.clientHeight;//屏幕的高度
            let fixedNum = 360;//保证时间选择器下拉框能在下方弹出来的、输入框距离屏幕底部最小的距离
            let num = clientHeight - offsetTop;//时间选择器输入框距离屏幕底部的距离
            let needNum = fixedNum - num + 10;
			if(needNum > 0){
				let dom = document.getElementsByClassName('form-detail')[0];
				dom.style.transform = "translateY(-" + needNum +"px)";

			}
		},
		handleTimerBlur() {
			if(this.timePickerFocus) {
				return
			}
			let dom = document.getElementsByClassName('form-detail')[0];
			dom.style.transform = "translateY(-" + 0 +"px)"
		}
	},
	watch:{
		leaveArray:{
			handler(ov,nv) {
				this.ruleForm.leaveArray = this.leaveArray;
				this.leaveArray.forEach(item => {
					item.times.forEach(time => {
						if(time.timeRange && time.timeRange.length > 0) {
							time.startTime.value = time.timeRange[0];
							time.endTime.value = time.timeRange[1];
						}
					})
				})
			},
			deep: true
		},
		tipContent:{
			handler(old,newData){
				if(this.tipContent && this.tipContent.leaveStartTime && this.tipContent.leaveEndTime){
					this.leaveArray.forEach(fitem => {
						fitem.times.forEach(iv => {
							if (iv.timeRange && iv.timeRange.length > 0) {
								if(iv.timeRange[0] == this.tipContent.leaveStartTime.substr(11) && iv.timeRange[1] == this.tipContent.leaveEndTime.substr(11)) {
									iv.tip.isShowTip = true
									iv.tip.tipContext = this.tipContent.name + '的请假时间和历史请假时间冲突！'
									this.isShowOwnTip = true
								}
							}
						})
					})
				}else{
					this.leaveArray.forEach(fitem => {
						fitem.times.forEach(iv => {
								iv.tip.isShowTip = false
								iv.tip.tipContext = ''
						})
					})
				}
			},
			deep: true
		}
	}
};
</script>
<style lang="scss" scoped>
.leave_ruleForm {

	::v-deep .el-range-editor--small .el-range__icon,
	.el-range-editor--small .el-range__close-icon {
		line-height: 36px;
	}
	/deep/ .el-range-editor--small.el-input__inner {
		height: 36px;
	}
}

.el-form-item {
	padding-right: 0 !important;
}

.dangerBtn {
	color: #fff;
	width: 64px;
	height: 32px;
	border-radius: 4px;
}

.calendar {
	.el-date-editor {
		width: 100%;
		padding: 3px 0;
		background-color: #fafcff;
		border: 0;
		border-radius: 2px;

		/deep/ .el-range__icon {
			display: none;
		}

		/deep/ .el-range__close-icon {
			width: 10px;
		}

		/deep/ .el-range-input {
			background-color: #fafcff;
		}

		/deep/ .el-range-separator {
			line-height: 26px;
			padding: 0;
		}
	}
}

/deep/ .el-card__body {
	padding: 10px !important;
}

::v-deep {
	.f-box {
		.leave-item {
			.leave-item-head {
				padding: 15px 0 15px 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.leave-item-head-left {
					font-weight: bolder;
					font-size: 16px;
				}
			}

			.leave-item-body {
				.el-date-editor {
					width: 100%;
				}
				.leave_data_item{

					.el-input__inner{
						padding: 0 30px;
					}
				}

				.flex-box.active {
					margin-bottom: 0px;
				}
				.flex-box {
					margin-bottom: 16px;
					display: flex;
					align-items: center;

					.first_div {
						line-height: 20px;
					}

					.index_first {
						color: #606266;
						margin-left: 12px;
					}

					label {
						width: 70px;
						margin-right: 20px;
						display: flex;
						align-items: center;
						justify-content: flex-end;

						.star {
							color: #e63a2e;
						}

						.text {
							color: #606266;
						}
					}

					.form-item-box {
						flex: 1;
						height: 36px;
						margin: 12px 0 14px 0px;
						border-radius: 4px;
						border: 1px solid #dcdfe6;
						display: flex;
						align-items: center;
						cursor: pointer;
						position: relative;
						.tip_class{
							position: absolute;
							top: 32px;
							left: 0;
							color: #e63a2e;
							font-size: 12px;
						}
						.el-form-item {
							height: 36px !important;
							padding: 0 10px;
							width: 100%;
							margin-bottom: 0;

							.el-form-item__content {
								margin-left: 0px !important;
								.el-date-editor {
									border: 0 !important;
									padding: 0 !important;
									.el-range-separator {
										line-height: 35px !important;
									}
								}
							}
						}
					}

					.el-icon-circle-plus,
					.el-icon-remove {
						margin: 0 0 0 12px;
						font-size: 22px;
						color: #5e96ff;
					}

					.el-icon-remove {
						color: #f34536;
					}
				}
			}
		}
	}
	.form-readonly{
		.el-input__prefix{
			display: none !important;
		}
		.form-item-box,
		.el-range-input{
			background-color: #F8F8F8;
		}
	}

	.dash-line {
		width: 100%;
		height: 0;
		border-top: 1px dashed #dcdfe6;
	}

	.el-input--small .el-input__inner {
		height: 36px !important;
	}
	.el-range-editor.is-disabled input {
		background-color: #f8f8f8;
	}
}
</style>
