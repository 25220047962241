<template>
    <div tyle="width: 100%" class="kits-leave-absence">
        <div class="title">
            <img class="transfer-icon" :src="transferIcon" alt="调休图标"/>
            <span class="label">特殊请假套件</span>
        </div>
        <el-form-item label="请假类型" required :label-width="labelWidth">
            <el-select v-model="ruleForm.region" placeholder="请选择" style="width: 100%">
                <el-option v-for="item in leaveList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <Leave></Leave>
        <el-form-item label="请假事由" prop="reasonLeave" :required="reasonLeaveRequiredType" :label-width="labelWidth">
            <el-input style="width: 100%" type="textarea" :rows="2" v-model="ruleForm.reasonLeave"></el-input>
        </el-form-item>
    </div>
</template>
<script>
import {CoworkingCampusOAVacationManagementModel} from "@/models/CoworkingCampusOAVacationManagement.js";
import {mapState} from "vuex";
import Leave from "./leave/leave.vue"

const kitName = "kitsSpecialLeave"
export default {
    name: kitName,
    props: {
        reasonLeaveRequired: {
            type: Number,
            default: () => {
                return 0
            }
        }
    },
    components:{
        Leave
    },
    data() {
        return {
            labelWidth: "94px",
            ruleForm: {
                value1: "",
                value2: "",
            },
            rules: {},
            leaveList: [],
            leaveArray: [
                {
                    date: [],
                    time: [[],[]],
                    duration: ''
                }
            ],
            format:'HH:mm',
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        transferIcon() {
            return require("./img/transfer-icon.png");
        },
        reasonLeaveRequiredType() {
            return this.reasonLeaveRequired === 0 ? false : true;
        },
        abstaractList() {
            const _this = this;
            let abstaractList = [
                {
                    label:'请假类型',
                    id:'leaveType',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'请假日期',
                    id:'leaveDates',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'开始时间',
                    id:'leaveStartTime',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'结束时间',
                    id:'leaveEndTime',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'请假事由',
                    id:'reasonLeave',
                    required:_this.reasonLeaveRequired === 0 ? false : true,
                    isBelongKit:true,
                    kitKey:kitName
                },
            ]

            return abstaractList;
        }
    },
    watch: {
        reasonLeaveRequiredType: {
            handler(n,o) {
                this.sendAbstract();
            },
    
        }
    },
    created() {
        this.init();
    },
    mounted() {
        this.sendAbstract();
    },
    methods: {
        init() {
            this.initPageData();
        },
        initPageData() {
            this.getList();
        },
        /**
         * @Description: 获取请假类型列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-04 11:18:12
         */
        getList() {
            const coworkingCampusOAVacationManagementModel =
                new CoworkingCampusOAVacationManagementModel();
            coworkingCampusOAVacationManagementModel
                .getUndeletedList({schoolId: this.schoolId, deleteStatus: "0",status: "1",})
                .then((res) => {
                    if (res.data.code == "200") {
                        const data = res.data.data;
                        let ar1 = [];
                        data.forEach((item) => {
                            let obj = {
                                label: "",
                                value: "",
                            };
                            this.$set(obj, "label", item.vacationName);
                            this.$set(obj, "value", item.id);
                            ar1.push(obj);
                        });
                        this.leaveList = ar1;
                        console.log(this.leaveList, "data--data");
                    }
                });
        },
        //选择日期输入框获取焦点
        handleFocus() {
            console.log('获取焦点');
        },
        //添加请假
        handleAddLeave() {

        },
        sendAbstract() {
            this.$eventDispatch('handlerKitAbstract', this.abstaractList)
        }
    },
};
</script>
<style lang="scss" scoped>
.title {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
        display: inline-block;
    }
    .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.el-form-item {
    padding-right: 0 !important;
}

</style>
