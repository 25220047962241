/*
 * @Author: 邵澳 1398037353@qq.com
 * @Date: 2023-10-31 17:19:27
 * @LastEditors: 邵澳 1398037353@qq.com
 * @LastEditTime: 2023-11-01 11:38:58
 * @FilePath: \cloud_campus_Front-end\school_end\src\xkUI\pages\kitsSpecialLeave\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import kitsSpecialLeave from './src/index.vue';

kitsSpecialLeave.install = function (Vue) {
    Vue.component(kitsSpecialLeave.name, kitsSpecialLeave)
}

export default kitsSpecialLeave;
